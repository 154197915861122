import { useRef, useEffect } from "react";
import { faEnvelopeOpen, faTrash, faPlay } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@edgetier/components";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { useHistory } from "react-router-dom";

import { ServerErrorModal } from "shared/modal";
import { IBlankEmail } from "redux/modules/email/email.types";
import createBlankEmail from "utilities/create-blank-email";
import { emailOperations } from "redux/modules/email";
import View from "constants/view";
import { IApplicationState } from "redux/types";
import { loadingBlockerOperations } from "redux/modules/loading-blocker";
import { IShowServerError } from "shared/modal/server-error-modal";

import { IProps } from "./send-email-button.types";
import "./send-email-button.scss";
import Modal, { ModalContent, ModalFooter, ModalHeader, useModal } from "@edgetier/modal";

/**
 * Button to continue the query being viewed by sending an email.
 * @param props.bookingId   The booking id of the interaction
 * @param props.interaction The interaction being viewed.
 */
const SendEmailButton = ({ bookingId, emailAddress, interaction, interactionDetailId }: IProps) => {
    const cancelTokenSource = useRef(Axios.CancelToken.source());
    useEffect(() => cancelTokenSource.current.cancel, []);

    const dispatch = useDispatch();
    const history = useHistory();

    const email = useSelector<IApplicationState, IBlankEmail | null>(({ email }) => email.emails.blank);

    const { modalProps, openModal } = useModal();

    /**
     * Create a blank email that is a part of the query currently being viewed and open it in the new email screen.
     * @param showServerError Method to show any server errors in a modal.
     */
    const setupNewEmail = async (showServerError: IShowServerError) => {
        if (interaction !== null) {
            try {
                dispatch(loadingBlockerOperations.showLoadingBlocker(true));
                const postData = {
                    languageId: interaction.languageId,
                    bookingId,
                    interactionDetailId,
                };

                const configuration = { cancelToken: cancelTokenSource.current.token };
                const email = await createBlankEmail(postData, configuration);

                dispatch(
                    emailOperations.storeEmail("blank", {
                        ...email,
                        bookingId,
                        bookingDetails: bookingId === null ? null : undefined,
                        createdAt: new Date(),
                        interactionDetailId,
                        replyToEmail: emailAddress,
                    })
                );
                history.push(View.CreateEmail);
            } catch (serverError) {
                if (Axios.isAxiosError(serverError)) {
                    showServerError(serverError);
                }
            } finally {
                dispatch(loadingBlockerOperations.hideLoadingBlocker(true));
            }
        }
    };

    /**
     * Set up a new blank email or show the modal giving users a choice of how to handle the current email.
     */
    const sendEmail = (showServerError: IShowServerError) => async () => {
        email === null ? await setupNewEmail(showServerError) : openModal();
    };

    /**
     * Discard the email that's currently in the new email screen and set up a new email.
     * @param showServerError Method to show any server errors in a modal.
     */
    const discardAndSend = (showServerError: IShowServerError) => async () => {
        dispatch(emailOperations.removeEmail("blank"));
        await setupNewEmail(showServerError);
    };

    /**
     * Continue the email currently in the new email screen.
     */
    const continueDraft = () => {
        history.push(View.CreateEmail);
    };

    return (
        <ServerErrorModal>
            {(showServerError) => (
                <>
                    <Modal {...modalProps}>
                        <ModalHeader>
                            <h2>Email In Progress</h2>
                        </ModalHeader>
                        <ModalContent>
                            <p>
                                You already have an email in progress on the new email screen, would you like to
                                continue working on that first?
                            </p>
                            <ModalFooter>
                                <div className="send-email-button__buttons">
                                    <Button onClick={continueDraft} icon={faPlay} styleName="positive">
                                        Continue draft email
                                    </Button>
                                    <Button
                                        onClick={discardAndSend(showServerError)}
                                        icon={faTrash}
                                        styleName="negative"
                                    >
                                        Discard and start new email
                                    </Button>
                                </div>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                    <div className="send-email-button">
                        <Button
                            className="button--no-modal"
                            disabled={interaction === null}
                            icon={faEnvelopeOpen}
                            onClick={sendEmail(showServerError)}
                            styleName="positive"
                        >
                            Send Email
                        </Button>
                    </div>
                </>
            )}
        </ServerErrorModal>
    );
};

export default SendEmailButton;
