import clickMenuButton from "./get-menu-button";
import { screen, waitFor } from "@testing-library/react";
import { ISelectOptions } from "./select";

/**
 * Open a select menu and wait for it to finish loading.
 * @param menuMatcher Optional matcher to find the menu in case there are multiple.
 * @returns           List of unselected items.
 */
const openSelectMenu = async (options: ISelectOptions = {}) => {
    await clickMenuButton(options);
    const list = await waitFor(() => screen.getByRole("listbox"));
    await waitFor(() => screen.getAllByRole("option"));
    return list;
};

export default openSelectMenu;
