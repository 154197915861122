import { Url } from "@edgetier/types";
import { AxiosError } from "axios";
import View from "constants/view";
import useSimpleQuery, { useSimplePrefetch } from "queries/use-simple-query";
import { UseQueryOptions } from "react-query";
import { useHistory } from "react-router-dom";
import { IUser } from "redux/application.types";
import urlJoin from "url-join";
import { getUserId } from "./local-storage";

export const usePrefetchCurrentUser = () => {
    const userId = getUserId();
    useSimplePrefetch(urlJoin(Url.Users, userId!.toString()), {});
};

/**
 * A query for requesting the current user from the backend.
 */
export const useCurrentUser = (configuration: UseQueryOptions<IUser, AxiosError> = {}) => {
    const userId = getUserId();
    const history = useHistory();

    // Redirect to login view if the userId is missing
    if (typeof userId !== "number") {
        history.push(View.Login);
    }

    return useSimpleQuery<IUser>(urlJoin(Url.Users, (userId ?? "").toString()), {
        enabled: typeof userId === "number",
        staleTime: 1000 * 60 * 5,
        ...configuration,
    });
};
