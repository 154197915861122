import Axios from "axios";
import Qs from "qs";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Url } from "@edgetier/types";
import { Spinner } from "@edgetier/components";

import View from "constants/view";
import axios from "utilities/axios";
import { authenticationOperations } from "redux/modules/authentication";

import { IProps } from "./authenticate.types";
import { IRole } from "types-for/permissions/permissions.types";
import "./authenticate.scss";
import { hotToastOperations } from "utilities-for/toast";

/**
 * After signing in, users are redirected to an authentication provider such as Salesforce, Zendesk etc. If that is
 * successful, they will redirect back to this page with information in the URL that needs to be passed on to our
 * backend.
 */
export const Authenticate: React.FunctionComponent<IProps> = ({ push, search }) => {
    const dispatch = useDispatch();

    const cancelTokenSource = React.useRef(Axios.CancelToken.source());
    useEffect(() => cancelTokenSource.current.cancel, []);

    const isAuthenticated = useRef(false);
    useEffect(() => {
        const authenticate = async () => {
            try {
                // Send the authentication details to the backend. It will return the user's role ID and email address.
                const { data } = await axios.post<{ email: string; roleId: IRole["roleId"] }>(
                    Url.ValidateUser,
                    { data: Qs.parse(search, { ignoreQueryPrefix: true }) },
                    { cancelToken: cancelTokenSource.current.token }
                );

                // The user has been authenticated. Save their details in the store.
                dispatch(authenticationOperations.signIn(data.email, data.roleId));
                push(View.AuthenticatedRedirect);
            } catch (serverError) {
                if (!Axios.isCancel(serverError) && Axios.isAxiosError(serverError)) {
                    localStorage.clear();
                    push(View.Login);
                    hotToastOperations.showServerErrorToast("Login Request Failed", serverError);
                }
            } finally {
                isAuthenticated.current = true;
            }
        };
        if (!isAuthenticated.current) {
            authenticate();
        }
    }, [dispatch, push, search]);

    return (
        <div className="authenticate">
            <Spinner fullPage={true} />
        </div>
    );
};

export default Authenticate;
