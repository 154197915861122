import React, { useLayoutEffect } from "react";
import classNames from "classnames";
import ResizeObserver from "resize-observer-polyfill";
import SelectMenu from "./select-menu";
import SelectPreview from "./select-preview";
import { doNothing } from "@edgetier/utilities";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IProps } from "./select.types";
import { mergeRefs, useLayer } from "react-laag";
import { MINIMUM_MULTIPLE_WIDTH, MINIMUM_SINGLE_WIDTH } from "./select.constants";
import { transformValues } from "./select.utilities";
import { useMemo, useRef, useState } from "react";
import SelectContext from "./select-context/select-context";
import { MenuOpenClosedIndicator } from "@edgetier/client-components";
import "./select.scss";

/**
 * Custom searchable select component.
 * @param props.addItemMenu                       A react component that will contain the menu to add an item.
 * @param props.children                          Optional function of how to render items.
 * @param props.description                       Description of the items being displayed.
 * @param props.disableMenuItems                  Whether the select menu items should be disabled or not.
 * @param props.getGroup                          Getter function to the group name from any item.
 * @param props.getLabel                          Getter function to the label from any item.
 * @param props.getValue                          Getter function to the value from any item.
 * @param props.isAllFilter                       When true, display "all" label for an empty filter.
 * @param props.isClearable                       If the user is allowed remove all values.
 * @param props.isDisabled                        Whether the component can be interacted with or not.
 * @param props.isLoading                         Items loading state.
 * @param props.isSearchable                      Whether to show a search box or not.
 * @param props.isSingleSelect                    When true, only one item can be selected.
 * @param props.isSortedAlready                   Only when false should the items be sorted by label.
 * @param props.isSourcedSelect                   Whether the select requests it's options or gets them passed to it.
 * @param props.items                             Options the user may choose from.
 * @param props.menuShouldScrollIntoView          Whether the menu should scroll into view when it's opened.
 * @param props.onClear                           A function that gets called when the select is cleared.
 * @param props.onClose                           A function that gets called when the select is closed.
 * @param props.onOpen                            Optional callback when the menu is opened.
 * @param props.onInputChange                     A function that gets called when the select's input value changes.
 * @param props.onSelect                          Handle the user selecting item(s).
 * @param props.placeholder                       Search box placeholder text.
 * @param props.placement                         Where the select menu should placed when it's open.
 * @param props.possiblePlacements                The options for where the menu can be placed.
 * @param props.previewPlaceholder                Custom content passed to select as placeholder.
 * @param props.selectedValues                    Currently selected values.
 * @param props.useAllItemsSelectedPreviewMessage Where the select preview should say "All items selected" in preview.
 * @param props.clearSearchOnSelection            Only when this is true should the search box be cleared when a user chooses an option
 */
const Select = <IItem extends {}, IValue extends {} = string>({
    addItemMenu,
    children,
    closeOnDisappear = false,
    description,
    disableMenuItems = false,
    className,
    getGroup,
    getGroupOrder,
    getLabel,
    getValue,
    hasBorder = false,
    isAllFilter = false,
    isClearable = false,
    isCompact = false,
    isMedium = false,
    isDisabled = false,
    isEmptyLabel,
    isLoading = false,
    isSearchable = true,
    labelledBy,
    isSingleSelect,
    isSortedAlready = false,
    isSourcedSelect = false,
    items: initialItems,
    menuShouldScrollIntoView = false,
    matchWidthToField,
    minimumWidth,
    noItemsFoundLabel,
    onClear: propOnClear = doNothing,
    onClose = doNothing,
    onOpen = doNothing,
    onInputChange,
    onSelect,
    message,
    placeholder = `Search ${description.toLowerCase()}s...`,
    previewPlaceholder,
    selectedValues: initialSelectedValues,
    useAllItemsSelectedPreviewMessage = true,
    placement = "bottom-center",
    possiblePlacements,
    clearSearchOnSelection = false,
    ...other
}: IProps<IItem, IValue>) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    // Make sure the selected values are always an array.
    const selectedValues = transformValues(initialSelectedValues);

    const clear = () => {
        onSelectItems([], []);
        propOnClear();
    };

    /**
     * Remove all selections.
     */
    const onClear = (mouseEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        clear();
        onClose();
        // Don't open the menu when this is clicked. Closing is fine though.
        if (!isOpen) {
            mouseEvent.stopPropagation();
        }
    };

    /**
     * Handle a user selected one or more values. If this is a single selection a single item is removed from the array
     * of selected values.
     * @param newSelectedValues Values the user has selected.
     */
    const onSelectItems = (newSelectedValues: IValue[], newSelectedItems: IItem[]) => {
        if (isSingleSelect) {
            onSelect(newSelectedValues[0] ?? null, newSelectedItems[0] ?? null);
        } else {
            onSelect(newSelectedValues, newSelectedItems);
        }
    };

    // Use only items that have a label and maybe them alphabetically.
    const items = useMemo(() => {
        const labelledItems = (Array.isArray(initialItems) ? initialItems : []).filter(
            (item) => typeof getLabel(item) === "string"
        );

        if (isSortedAlready) {
            if (typeof getGroup === "function") {
                return labelledItems.sort((itemOne, itemTwo) => getGroup(itemOne).localeCompare(getGroup(itemTwo)));
            }
            return labelledItems;
        }

        return labelledItems
            .slice()
            .sort((itemOne, itemTwo) => getLabel(itemOne).localeCompare(getLabel(itemTwo)))
            .sort((itemOne, itemTwo) =>
                // Sort by group if any
                typeof getGroup === "function" ? getGroup(itemOne).localeCompare(getGroup(itemTwo)) : 0
            )
            .sort((itemOne, itemTwo) =>
                // Sort by group order if exists
                typeof getGroupOrder === "function" ? getGroupOrder(itemOne) - getGroupOrder(itemTwo) : 0
            );
    }, [getLabel, getGroup, initialItems, isSortedAlready, getGroupOrder]);

    // Get the currently selected items.
    const selectedItems = useMemo(
        () => items.filter((item) => selectedValues.includes(getValue(item))),
        [getValue, items, selectedValues]
    );

    /**
     * Close the menu.
     */
    const close = () => {
        setIsOpen(false);
        onClose();
    };

    /**
     * Open or close the menu.
     */
    const toggle = () => {
        if (isDisabled) {
            return;
        }

        setIsOpen(!isOpen);

        if (!isOpen) {
            onOpen();
        } else {
            onClose();
        }
    };

    const [isLockedOpen, setIsLockedOpen] = useState(false);

    const { renderLayer, triggerProps, layerProps } = useLayer({
        auto: true,
        isOpen,
        onDisappear: closeOnDisappear ? close : doNothing,
        onOutsideClick: isLockedOpen ? doNothing : close,
        onParentClose: close,
        triggerOffset: 4,
        possiblePlacements,
        placement,
        ResizeObserver,
    });

    useLayoutEffect(() => {
        if (
            menuShouldScrollIntoView &&
            isOpen &&
            ref.current !== null &&
            typeof ref.current.scrollIntoView === "function"
        ) {
            ref.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [isOpen, menuShouldScrollIntoView]);

    // Try to make the menu have at least the same width as the trigger.
    const menuMinimumWidth = Math.max(
        ref.current?.clientWidth ?? 0,
        typeof minimumWidth !== "undefined"
            ? minimumWidth
            : isSingleSelect
            ? MINIMUM_SINGLE_WIDTH
            : MINIMUM_MULTIPLE_WIDTH
    );

    const menuMaximumWidth = matchWidthToField ? ref.current?.clientWidth ?? undefined : undefined;

    return (
        <>
            {isOpen &&
                renderLayer(
                    <SelectContext.Provider
                        value={{
                            close,
                            lockOpen: () => setIsLockedOpen(true),
                            unlock: () => setIsLockedOpen(false),
                        }}
                    >
                        <div
                            className={classNames("select__menu", className, {
                                "select__menu--is-compact": isCompact,
                                "select__menu--is-medium": isMedium,
                                "select__menu--is-multiple-select": isSingleSelect !== true,
                                "select__menu--is-single-select": isSingleSelect,
                            })}
                            {...layerProps}
                            style={{ ...layerProps.style, maxWidth: menuMaximumWidth, minWidth: menuMinimumWidth }}
                        >
                            <SelectMenu<IItem, IValue>
                                addItemMenu={addItemMenu}
                                clear={clear}
                                close={close}
                                description={description}
                                disableMenuItems={disableMenuItems}
                                getGroup={getGroup}
                                getLabel={getLabel}
                                getValue={getValue}
                                isLoading={isLoading}
                                isSearchable={isSearchable}
                                isSingleSelect={isSingleSelect}
                                isSourcedSelect={isSourcedSelect}
                                items={items}
                                message={message}
                                noItemsFoundLabel={noItemsFoundLabel}
                                onSelectItems={onSelectItems}
                                onInputChange={onInputChange}
                                placeholder={placeholder}
                                selectedValues={selectedValues}
                                clearSearchOnSelection={clearSearchOnSelection}
                                {...other}
                            >
                                {children}
                            </SelectMenu>
                        </div>
                    </SelectContext.Provider>
                )}

            <div
                aria-disabled={isDisabled}
                className={classNames("select__trigger", {
                    "select--is-disabled": isDisabled,
                    "select--has-border": hasBorder,
                })}
                {...triggerProps}
                {...other}
                ref={mergeRefs(triggerProps.ref, ref)}
                onClick={toggle}
                role="menu"
            >
                <div className="select__preview-container">
                    <SelectPreview<IItem, IValue>
                        description={description}
                        getLabel={getLabel}
                        getValue={getValue}
                        isAllFilter={isAllFilter}
                        isEmptyLabel={isEmptyLabel}
                        isLoading={isLoading}
                        isSingleSelect={isSingleSelect ?? false}
                        items={items}
                        previewPlaceholder={previewPlaceholder}
                        selectedItems={selectedItems}
                        useAllItemsSelectedPreviewMessage={useAllItemsSelectedPreviewMessage}
                    />
                    <input aria-hidden className="select__dummy-input" id={labelledBy} />
                </div>

                <div className="select__controls">
                    {isClearable && selectedItems.length > 0 && (
                        <div
                            aria-label="Clear selections"
                            className="select__control select__clear"
                            onClick={onClear}
                            role="button"
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    )}
                    <MenuOpenClosedIndicator isOpen={isOpen} />
                </div>
            </div>
        </>
    );
};

export default Select;
