import React, { FunctionComponent } from "react";
import { Tooltip } from "@edgetier/components";
import { faCircleQuestion } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IInfoTooltipProps } from "./info-tooltip.types";

const InfoTooltip: FunctionComponent<IInfoTooltipProps> = (props) => {
    return (
        <Tooltip useArrow {...props}>
            <FontAwesomeIcon fixedWidth={true} icon={faCircleQuestion} />
        </Tooltip>
    );
};

export default InfoTooltip;
