import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { NavLink } from "react-router-dom";

import { IProps } from "./ellipsis-menu-item.types";
import { EllipsisMenuContext } from "~/index";
import "./ellipsis-menu-item.scss";

/**
 * A list item in the Ellipsis menu.
 * @param props.children   The content of the menu item.
 * @param props.icon       The icon displayed next to the menu item.
 * @param props.isDisabled Boolean indicating if the option is disabled.
 * @param props.href       Pathname for the list item action
 * @param props.linkState  Any state details passed with the link
 * @param props.linkTarget Where to display the linked URL
 * @param props.onClick    A function that runs after the item is clicked.
 */
const EllipsisMenuItem = ({
    children,
    closeAfterClick = true,
    icon,
    isDisabled = false,
    href,
    linkState,
    linkTarget,
    onClick,
}: IProps) => {
    const { close } = useContext(EllipsisMenuContext);

    return (
        <li
            className={classnames("ellipsis-menu__item", {
                "ellipsis-menu__item--disabled": isDisabled,
                "ellipsis-menu__item--has-link": typeof href === "string",
            })}
            onClick={(event) => {
                if (isDisabled) {
                    return;
                }
                if (typeof onClick !== "undefined") {
                    onClick(event);
                }
                if (closeAfterClick) {
                    close();
                }
            }}
        >
            {typeof href === "string" ? (
                <NavLink
                    to={{ pathname: href, state: linkState }}
                    className="ellipsis-menu__item-link"
                    target={linkTarget}
                >
                    {typeof icon !== "undefined" && <FontAwesomeIcon fixedWidth icon={icon} />}
                    {children}
                </NavLink>
            ) : (
                <>
                    {typeof icon !== "undefined" && <FontAwesomeIcon fixedWidth icon={icon} />}
                    {children}
                </>
            )}
        </li>
    );
};

export default EllipsisMenuItem;
