import { Url } from "@edgetier/types";
import { useChatEmailToggles } from "hooks-for/users/use-chat-email-toggles";
import { useCurrentUserState } from "hooks-for/users/use-user-states/use-user-states";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "redux/application.types";
import { authenticationOperations } from "redux/modules/authentication";
import { hotToastOperations } from "utilities-for/toast";
import { IApplicationState } from "redux/types";
import urlJoin from "url-join";
import axios from "utilities/axios";
import { useCurrentUser } from "utilities/use-current-user";
import UserStateContext from "../user-state-context";

/**
 * Check what the current user state is and toggle chat and email on or off accordingly. Return true when the check is
 * done.
 */
export const useUserStateCheck = () => {
    const dispatch = useDispatch();
    const [shouldCheck, setShouldCheck] = useState(true);
    const { toggleChat, toggleEmail, toggleProactiveChat } = useChatEmailToggles();
    const { data: user, isError: isUserError } = useCurrentUser();

    const { setUserStateId } = useContext(UserStateContext);

    const { data: userState, isError: isUserStatesError } = useCurrentUserState(
        user,
        {},
        {
            onSuccess: async (state) => {
                // The user should never be in an offline state when they're logged in. If they are, something has gone
                // wrong and their state should be made unavailable.
                if (state?.userStateId === UserState.Offline) {
                    try {
                        toggleChat(false);
                        toggleEmail(false);
                        toggleProactiveChat(false);

                        await axios.patch(urlJoin(Url.UsersMe), { userStateId: UserState.Unavailable });

                        hotToastOperations.showInfoToast(
                            "State Change",
                            "You have been set to unavailable because you were inactive or lost connection."
                        );
                    } catch (error) {
                        hotToastOperations.showErrorToast(
                            "Error Changing State",
                            "There was an error when changing your state. You are being logged out."
                        );
                        dispatch(authenticationOperations.signOut());
                    }
                    return;
                }

                if (shouldCheck) {
                    // Regardless of whether isChatEnabled is true or false, chat should be enabled if
                    // isProactiveChatEnabled is true,
                    toggleChat((state?.isChatEnabled ?? false) || (state?.isProactiveChatEnabled ?? false));
                    toggleEmail(state?.isEmailEnabled ?? false);
                    toggleProactiveChat(state?.isProactiveChatEnabled ?? false);
                    setUserStateId(state?.userStateId ?? UserState.Unavailable);
                }
            },
        }
    );

    const isChatEnabled = useSelector<IApplicationState>(({ chat }) => chat.enabled);
    const isProactiveChatEnabled = useSelector<IApplicationState>(({ proactiveChat }) => proactiveChat.enabled);
    const isError = isUserError || isUserStatesError;

    // If there's an error or shouldCheck is false, return true so the spinner doesn't show. Otherwise, make sure the
    // user's state matches the chat state in redux.
    const isCheckDone =
        isError ||
        !shouldCheck ||
        (typeof userState !== "undefined" && (userState?.isChatEnabled ?? false) === isChatEnabled) ||
        (typeof userState !== "undefined" && (userState?.isProactiveChatEnabled ?? false) === isProactiveChatEnabled);

    // The check only needs to be done once, so, when the check is done, set shouldCheck to false so the spinner
    // doesn't get triggered again.
    useEffect(() => {
        if (shouldCheck && isCheckDone) {
            setShouldCheck(false);
        }
    }, [isCheckDone, shouldCheck]);

    return isCheckDone;
};
