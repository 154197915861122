import { FunctionComponent, memo, useEffect } from "react";
import { IProps } from "./authenticated-redirect.types";
import View from "constants/view";
import { Redirect, useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { Spinner } from "@edgetier/components";
import { Url } from "@edgetier/types";
import { useCurrentUser } from "utilities/use-current-user";
import axios from "utilities/axios";
import { useRolePermissions } from "@edgetier/roles-permissions";
import { findRedirectPage } from "./authenticated-redirect.utilities";

/**
 * Once the user is loggged in, redirect them to the appropriate page based on their permissions.
 */
const AuthenticatedRedirect: FunctionComponent<IProps> = () => {
    const history = useHistory();
    const { data: user, isLoading: isUserLoading, isError: isUserError } = useCurrentUser();

    // Request the user's permissions
    const {
        data: userPermissions,
        isLoading: isUserPermissionsLoading,
        isError: isRolePermissionsError,
    } = useRolePermissions(Url.RolePermissions, axios, user?.roleId.toString()!, {
        enabled: typeof user !== "undefined",
    });

    useEffect(() => {
        if (isUserError || isRolePermissionsError) {
            history.push(View.Login);
            toast.error("There was an error logging you in. Please try again.");
        }
    }, [history, isRolePermissionsError, isUserError]);

    if (isUserLoading || isUserPermissionsLoading) {
        return <Spinner fullPage />;
    }

    return <Redirect to={findRedirectPage(userPermissions)} />;
};

export default memo(AuthenticatedRedirect);
