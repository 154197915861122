import LoadingBar from "react-redux-loading-bar";
import { Fragment } from "react";
import ReduxToastr from "react-redux-toastr";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as sentry from "@sentry/react";
import Modal from "components/modal/modal";
import View from "constants/view";
import LoadingBlocker from "components/loading-blocker/loading-blocker";
import Login from "components/login/login";
import Authenticate from "components/authenticate";
import { ServerErrorModal } from "shared/modal";
import { Toaster } from "react-hot-toast";
import AuthenticatedRoutes from "./authenticated-routes";
import StackingLayers from "./stacking-layers";
import ErrorFallback from "components-for/errors/error-fallback";
import AuthenticatedRedirect from "components-for/authentication/authenticated-redirect";
import OAuthCallback from "components/callbacks/oauth/oauth-callback";

/**
 * Render all global components like the loading block, toastr etc. and all basic high-level routes like the login
 * screen. Most other routes require the user to be logged in so they are contained in another component.
 * @returns Various top-level components and routes.
 */
const Application = () => (
    <Fragment>
        <ReduxToastr
            preventDuplicates
            timeOut={2500}
            position="bottom-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
        />
        <Toaster position="bottom-right" toastOptions={{ style: { backgroundColor: "#34495e", color: "white" } }} />
        <LoadingBar className="loading" />
        <Modal />

        <LoadingBlocker>
            {/* Render StackingLayers as a child of LoadingBlocker so loading blockers will work in modals */}
            <StackingLayers />
            <BrowserRouter>
                <Switch>
                    <Route path={View.Main}>
                        {({ history, location }) => (
                            <ServerErrorModal>
                                {(showServerError) => (
                                    <AuthenticatedRoutes
                                        pathname={location.pathname}
                                        push={history.push}
                                        showServerError={showServerError}
                                    />
                                )}
                            </ServerErrorModal>
                        )}
                    </Route>

                    <Route exact={true} path={View.CallbackOAuth}>
                        {() => <OAuthCallback />}
                    </Route>

                    <Route exact={true} path={View.Login}>
                        <ServerErrorModal>
                            {(showServerError) => <Login showServerError={showServerError} />}
                        </ServerErrorModal>
                    </Route>

                    <Route exact={true} path={View.Authenticate}>
                        {({ history, location }) => <Authenticate push={history.push} search={location.search} />}
                    </Route>

                    <Route path={View.AuthenticatedRedirect} component={AuthenticatedRedirect} />
                </Switch>
            </BrowserRouter>
        </LoadingBlocker>
    </Fragment>
);

export default sentry.withErrorBoundary(Application, { fallback: ErrorFallback, showDialog: true });
