import { IPermission, Permission } from "@edgetier/types";
import View from "constants/view";

export const PERMISSION_PAGE_MAPPING = new Map<Permission, string | View>();

PERMISSION_PAGE_MAPPING.set(Permission.HandleInteraction, View.AgentHome);
PERMISSION_PAGE_MAPPING.set(Permission.ViewReporting, View.ReportingChat);
PERMISSION_PAGE_MAPPING.set(Permission.ViewSearch, View.SearchInteractions);
PERMISSION_PAGE_MAPPING.set(Permission.EditTemplate, View.Templates);
PERMISSION_PAGE_MAPPING.set(Permission.EditRole, View.Permissions);
PERMISSION_PAGE_MAPPING.set(Permission.EditForm, View.SettingsForms);
PERMISSION_PAGE_MAPPING.set(Permission.EditFormField, View.SettingsForms);
PERMISSION_PAGE_MAPPING.set(Permission.EditUser, View.Users);
PERMISSION_PAGE_MAPPING.set(Permission.EditAgentState, View.SettingsUserStates);
PERMISSION_PAGE_MAPPING.set(Permission.EditWidget, View.SettingsSetups);
PERMISSION_PAGE_MAPPING.set(Permission.EditChatBan, View.SettingsBans);
PERMISSION_PAGE_MAPPING.set(Permission.EditLanguage, View.SettingsLanguages);
PERMISSION_PAGE_MAPPING.set(Permission.EditEmailRoutingRule, View.SettingsEmailRoutingRules);
PERMISSION_PAGE_MAPPING.set(Permission.EditDepartment, View.SettingsDepartments);
PERMISSION_PAGE_MAPPING.set(Permission.EditSkill, View.SettingsSkills);
PERMISSION_PAGE_MAPPING.set(Permission.EditTextLocalisation, View.SettingsTextLocalisations);
PERMISSION_PAGE_MAPPING.set(Permission.EditSystemSetting, View.SettingsSystem);
PERMISSION_PAGE_MAPPING.set(Permission.EditLogic, View.LogicFlow);
PERMISSION_PAGE_MAPPING.set(Permission.EditTheme, View.SettingsThemes);
PERMISSION_PAGE_MAPPING.set(Permission.EditBrand, View.SettingsBrands);

/**
 * Using user's role and permission find the appropriate available page to redirect after a successful authentication.
 * @param userRolePermissions   Permissions selected for user's role.
 */
export const findRedirectPage = (userRolePermissions?: IPermission[]) => {
    if (Array.isArray(userRolePermissions) && userRolePermissions.length > 0) {
        for (const [permission, page] of Array.from(PERMISSION_PAGE_MAPPING)) {
            const hasPermission = userRolePermissions.some(({ permissionId }) => permission === permissionId);
            if (hasPermission) {
                return page;
            }
        }
    }

    return View.Login;
};
